document.addEventListener('turbo:submit-start', () => {
  Turbo.navigator.delegate.adapter.showProgressBar();
})

document.addEventListener('turbo:submit-end', (e) => {
  Turbo.navigator.delegate.adapter.progressBar.hide();
  if (e.detail.success === false) {
    e.target.previousElementSibling.scrollIntoView(true)
  }
})

document.addEventListener('turbo:before-fetch-request', () => {
  Turbo.navigator.delegate.adapter.showProgressBar();
})

document.addEventListener('turbo:before-fetch-response', (e) => {
  Turbo.navigator.delegate.adapter.progressBar.hide();
  if (e.detail.success === false) {
    e.target.previousElementSibling.scrollIntoView(true)
  }
})
